import React from 'react'
import logo from '../../asset/mainfab_logo.svg'
const NavigationBar = ({ data, toggle, setToggle }) => {
	return (
		<nav className='flex justify-center p-4 fixed w-full z-10 top-0 bg-gray-00'>
			<div className='flex container items-center justify-between flex-wrap'>
				<div className='flex items-center flex-shrink-0 text-white mr-6'>
					<span
						className='text-white no-underline hover:text-white hover:no-underline'
						href='#'
					>
						<div className='text-2xl pl-2'>
							<span
								style={{ backgroundImage: `url(${logo})` }}
								className='flex w-16 h-9 justify-center items-center bg-contain bg-no-repeat bg-center'
							/>
						</div>
					</span>
				</div>

				<div className='block lg:hidden'>
					<button
						id='nav-toggle'
						onClick={() => setToggle(!toggle)}
						className='flex items-center px-3 py-2 text-gray-50 hover:text-gray-500 hover:border-white'
					>
						<svg
							className='fill-current h-6 w-6'
							viewBox='0 0 20 20'
							xmlns='http://www.w3.org/2000/svg'
						>
							<title>Menu</title>
							<path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
						</svg>
					</button>
				</div>

				<div
					className={`w-full flex-grow lg:flex lg:items-center ${
						toggle ? '' : 'hidden'
					} lg:w-auto pt-6 lg:pt-0`}
					id='nav-content'
				>
					<ul className='list-reset lg:flex justify-end flex-1 items-center'>
						{data.map((item, index) => {
							return (
								<li className='mr-3' key={index}>
									<span
										className='inline-block py-2 px-4 text-white no-underline'
										href='#'
									>
										{item}
									</span>
								</li>
							)
						})}
					</ul>
				</div>
			</div>
		</nav>
	)
}

export default NavigationBar
