import React from 'react'
import { solution } from './solutionData'
import './solution.scss'
import Feature from '../../components/feature/feature'
import Carousel from '../../components/carousel/carousel'

const Solution = () => {
	return (
		<section className='solution-container flex relative justify-center bg-primary'>
			<div className='flex flex-col justify-center items-center container'>
				<Carousel solution={solution} />
				<Feature />
			</div>
		</section>
	)
}

export default Solution
