import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import './home.scss'
import heroBg from '../../asset/hero_section.svg'

const Homepage = () => {
	useEffect(() => {
		const tl = gsap.timeline({ defaults: { duration: 0.15 } })
		tl.from('.hero-title', {
			opacity: 0,
			duration: 0.75,
			delay: 0.3,
			y: 100,
			ease: 'power3',
		})
			.from('.hero-content', {
				opacity: 0,
				duration: 0.65,
				y: 85,
				ease: 'power2',
			})
			.from('.hero-contactBtn', {
				opacity: 0,
				duration: 0.55,
				y: 50,
				ease: 'power',
			})
			.from('.hero-svg', {
				opacity: 0,
				duration: 0.5,
				x: 200,
				ease: 'power3',
			})
	}, [])
	return (
		<section className='relative bg-red-600 text-gray-600 body-font'>
			<div className='container mx-auto flex px-5 pt-20 pb-28 md:pb-48 md:flex-row flex-col items-center'>
				<div className='container lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center'>
					<h1 className='hero-title title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-50'>
						Mainfab Engineering
					</h1>
					<p className='hero-content mb-8 leading-relaxed'>
						We designs and constructs oil and gas projects that are tailored to
						the specialized equipment, systems, and facilities your company
						utilizes.We are experienced with the unique challenges involved in
						designing and building oil and gas facilities and experience,
						commitment, and quality drive us on every project.
					</p>
					<div className='hero-contactBtn flex justify-center'>
						<button className='inline-flex text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg'>
							Let's start conversation with us
						</button>
					</div>
				</div>
				<div className='hero-svg lg:max-w-lg lg:w-full md:w-1/2 w-5/6'>
					<img
						className='object-cover object-center rounded'
						alt='hero'
						src={heroBg}
					/>
				</div>
			</div>
			<svg
				className='absolute bottom-0'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 1440 320'
			>
				<path
					fill='#E9322E'
					fillOpacity='1'
					d='M0,256L40,234.7C80,213,160,171,240,165.3C320,160,400,192,480,213.3C560,235,640,245,720,229.3C800,213,880,171,960,176C1040,181,1120,235,1200,256C1280,277,1360,267,1400,261.3L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z'
				></path>
			</svg>
		</section>

		// <div
		// 	className='homepage-container relative w-full h-screen pt-8 bg-red-600'
		// 	data-scroll-container
		// >
		// 	<div className='flex border-blue-300 border-2'>
		// 		<h1>Mainfab Engineering</h1>
		// 		<h2>We provide a solution to your busineess</h2>
		// 	</div>
		// 	<img className='border-2 border-blue-300' src={heroBg} alt='' />
		// </div>
	)
}

export default Homepage
