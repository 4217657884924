import React from 'react'
import { feature } from './featureData'
import FeatureModel from './featureModel'

const Feature = () => {
	return (
		<section className='bg-primary text-gray-50 body-font'>
			<div className='px-5 py-10 mx-auto'>
				<div className='mb-8 sm:text-3xl text-2xl font-medium title-font text-center text-gray-50'>
					<h1
						data-scroll
						data-scroll-direction='horizontal'
						data-scroll-speed='-4'
					>
						Why Choose Us ?
					</h1>
					<h1
						data-scroll
						data-scroll-direction='horizontal'
						data-scroll-speed='4'
					>
						Our Advantage
					</h1>
				</div>
				<div className='flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6'>
					{feature.map((item, index) => {
						return <FeatureModel key={index} data={item} />
					})}
				</div>
			</div>
		</section>
	)
}

export default Feature
