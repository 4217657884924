import { useEffect } from 'react'
import locomotiveScroll from 'locomotive-scroll'
import 'locomotive-scroll/dist/locomotive-scroll.css'
import { ScrollTrigger } from 'gsap/all'
import { gsap } from 'gsap'
import { processGsap } from '../pages/process/processGsapFx'
export default function useLocomotive(start, scrollEl, setScroll) {
	useEffect(() => {
		if (!start) return
		const element = scrollEl.current
		gsap.registerPlugin(ScrollTrigger)
		// eslint-disable-next-line
        const locoScroll = new locomotiveScroll({
			el: element,
			smooth: true,
			multiplier: 1,
			smartphone: {
				smooth: true,
			},
			tablet: {
				smooth: true,
			},
		})

		locoScroll.on('scroll', ScrollTrigger.update)

		ScrollTrigger.scrollerProxy(element, {
			scrollTop(value) {
				return arguments.length
					? locoScroll.scrollTo(value, 0, 0)
					: locoScroll.scroll.instance.scroll.y
			}, // we don't have to define a scrollLeft because we're only scrolling vertically.
			getBoundingClientRect() {
				return {
					top: 0,
					left: 0,
					width: window.innerWidth,
					height: window.innerHeight,
				}
			},
			// LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
			pinType: document.querySelector('.main-container').style.transform
				? 'transform'
				: 'fixed',
		})

		processGsap()
		// each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
		ScrollTrigger.addEventListener('refresh', () => locoScroll.update())
		ScrollTrigger.refresh()
		// eslint-disable-next-line
    },[start]);
}
