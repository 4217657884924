import { GiTakeMyMoney } from 'react-icons/gi'
import { BsGraphUp } from 'react-icons/bs'
import { HiOutlineBadgeCheck } from 'react-icons/hi'
export const feature = [
	{
		feature: 'improve efficiency',
		content:
			'By reducing the burden of managing support functions, staffing and day to day operations, your business can concentrate further on core areas.',
		icon: <BsGraphUp size='23' />,
	},
	{
		feature: 'reduce cost',
		content:
			'Our solutions are crafted to bring effective change in your business by ensuring cost-effective and reliable operation methodologies.',
		icon: <GiTakeMyMoney size='32' />,
	},
	{
		feature: 'Overarching Impact',
		content:
			'By ensuring quality process management we can ensure a comprehensive impact on your business.',
		icon: <HiOutlineBadgeCheck size='30' />,
	},
]
