import React from 'react'
import './index.scss'

//pages
import Home from './homepage/home'
import About from './about/about'
import Solution from './solution/solution'
import Process from './process/process'
import Contact from './contact/contact'

function Homepage() {
	return (
		<div className='relative'>
			<Home />
			<About />
			<Solution />
			<Process />
			<Contact />
		</div>
	)
}

export default Homepage
