import React from 'react'
import './contact.scss'

const Contact = () => {
	return (
		<footer className='contact-container flex flex-col relative w-full bg-red-600'>
			<svg
				className='absolute top-0 w-full transform rotate-180'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 1440 320'
			>
				<path
					fill='#E9322E'
					fillOpacity='1'
					d='M0,256L40,234.7C80,213,160,171,240,165.3C320,160,400,192,480,213.3C560,235,640,245,720,229.3C800,213,880,171,960,176C1040,181,1120,235,1200,256C1280,277,1360,267,1400,261.3L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z'
				></path>
			</svg>
			<div className='flex w-full justify-center bg-600'>
				<div className='container flex flex-col sm:flex-row justify-between'>
					<div className='flex-shrink-0 p-10 md:mx-0 mx-auto text-center md:text-left'>
						<span className='flex title-font font-medium items-center md:justify-start justify-center text-gray-50'>
							<div className='w-8 y-8'>
								<img src='./favicon.png' alt='mainfab logo' />
							</div>
							<span className='ml-3 text-xl'>Mainfab Engineering</span>
						</span>
						<ul className='mt-2 text-sm text-gray-50'>
							<li className='mb-5'>
								K60564, Jalan Resak 1/2, <br /> Pusat Perniagaan Bukit Kuang 2,
								<br /> 24007 Kemaman, Terengganu
							</li>
							<li className='mb-5'>
								<a className='underline' href='tel:+609-863 7301'>
									+609-863 7301
								</a>
							</li>
							<li>
								<a
									className='underline'
									href='mailto:contact@mainfabengineering.com'
								>
									contact@mainfabengineering
								</a>
							</li>
						</ul>
					</div>
					<iframe
						className='mt-10'
						title='mainfab engineering location'
						frameBorder='0'
						src='https://www.google.com/maps/embed/v1/place?key=AIzaSyBklcp9hvoB9vJ_zDhcjqhyFF2il5fRBaQ&amp;q=Mainfab+Engineering+Sdn+Bhd,Terengganu+Malaysia'
						allowFullScreen=''
					></iframe>
				</div>
			</div>
			<section className='text-gray-600 body-font'>
				<div className='container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col'>
					<p className='text-sm text-gray-50 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4'>
						© 2020 Mainfab —
						<span
							href='https://twitter.com/knyttneve'
							className='text-gray-50 ml-1'
							rel='noopener noreferrer'
							target='_blank'
						>
							© 2020 Mainfab Engineering (925581-U). All rights reserved.
						</span>
					</p>
					<span className='inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start'>
						<span className='text-blue-600'>
							<svg
								fill='currentColor'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								className='w-5 h-5'
								viewBox='0 0 24 24'
							>
								<path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
							</svg>
						</span>
						<span className='ml-3 text-blue-400'>
							<svg
								fill='currentColor'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								className='w-5 h-5'
								viewBox='0 0 24 24'
							>
								<path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
							</svg>
						</span>
						<span className='ml-3 text-gray-800'>
							<svg
								fill='none'
								stroke='currentColor'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								className='w-5 h-5'
								viewBox='0 0 24 24'
							>
								<rect width='20' height='20' x='2' y='2' rx='5' ry='5'></rect>
								<path d='M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01'></path>
							</svg>
						</span>
						<span className='ml-3 text-blue-500'>
							<svg
								fill='currentColor'
								stroke='currentColor'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='0'
								className='w-5 h-5'
								viewBox='0 0 24 24'
							>
								<path
									stroke='none'
									d='M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z'
								></path>
								<circle cx='4' cy='4' r='2' stroke='none'></circle>
							</svg>
						</span>
					</span>
				</div>
			</section>
		</footer>
	)
}

export default Contact
