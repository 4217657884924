import { gsap } from 'gsap'

export function processGsap() {
	const processTl = gsap.timeline({
		scrollTrigger: {
			trigger: '.process-container',
			scroller: '.main-container',
			start: 'top bottom',
		},
	})
	processTl
		.from('.content-title', {
			opacity: 0,
			x: -200,
		})
		.from(
			'.gambo',
			{
				opacity: 0,
				x: 60,
				ease: 'power3',
			},
			'-=1'
		)
		.from('.first-content', {
			opacity: 0,
			y: 50,
		})
		.from('.second-content', {
			opacity: 0,
			y: 50,
			delay: 0.4,
		})
		.from('.third-content', {
			opacity: 0,
			y: 50,
			delay: 0.4,
		})
		.from('.last-content', {
			opacity: 0,
			y: 50,
			delay: 0.4,
		})
}
