import React from 'react'
import aboutImg from '../../asset/about.jpg'
const About = () => {
	return (
		<div className='bg-primary w-screen'>
			<div className='mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20'>
				<div className='grid gap-5 row-gap-8 px-4 lg:grid-cols-2'>
					<div data-scroll data-scroll-speed='6'>
						<img
							className='object-cover w-full h-56 rounded shadow-lg sm:h-96'
							src={aboutImg}
							alt=''
						/>
					</div>
					<div
						className='flex flex-col justify-center'
						data-scroll
						data-scroll-speed='3'
					>
						<div className='max-w-xl mb-6'>
							<h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl sm:leading-none'>
								We Are
								<br className='hidden md:block' />
								Mainfab{' '}
								<span className='relative px-1'>
									<div className='absolute inset-x-0 bottom-0 h-3 transform -skew-x-12 bg-teal-accent-400' />
									<span className='relative inline-block text-deep-purple-accent-400'>
										Engineering
									</span>
								</span>
							</h2>
							<p className='text-base text-gray-100 md:text-lg'>
								Mainfab Engineering provides professional and technical
								solutions to deliver safe, well‑executed, capital‑efficient
								engineering, procurement and construction (EPC) projects to
								clients around the world.
							</p>
						</div>
						<div className='grid gap-5 row-gap-8 sm:grid-cols-2'>
							<div className='bg-white border-l-4 shadow-sm border-deep-purple-accent-400'>
								<div className='h-full p-5 border border-l-0 rounded-r'>
									<h6 className='mb-2 font-semibold leading-5'>OnShore</h6>
									<p className='text-sm text-gray-900'>
										Lookout flogging bilge rat main sheet bilge water nipper
										fluke to go on account heave down.
									</p>
								</div>
							</div>
							<div className='bg-white border-l-4 shadow-sm border-deep-purple-accent-400'>
								<div className='h-full p-5 border border-l-0 rounded-r'>
									<h6 className='mb-2 font-semibold leading-5'>Offshore</h6>
									<p className='text-sm text-gray-900'>
										Those options are already baked in with this model shoot me
										an email clear.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section className='text-gray-600 body-font'>
					<div className='container px-5 pt-14 pb-40 mx-auto'>
						<div className='flex flex-wrap -m-4 text-center'>
							<div
								className='p-4 sm:w-1/4 w-1/2'
								data-scroll
								data-scroll-delay='0.025'
								data-scroll-speed='4'
							>
								<h2 className='title-font font-medium sm:text-4xl text-3xl text-gray-50'>
									22
								</h2>
								<p className='leading-relaxed'>Client</p>
							</div>
							<div
								className='p-4 sm:w-1/4 w-1/2'
								data-scroll
								data-scroll-delay='0.05'
								data-scroll-speed='4'
							>
								<h2 className='title-font font-medium sm:text-4xl text-3xl text-gray-50'>
									400
								</h2>
								<p className='leading-relaxed'>Complete</p>
							</div>
							<div
								className='p-4 sm:w-1/4 w-1/2'
								data-scroll
								data-scroll-delay='0.025'
								data-scroll-speed='4'
							>
								<h2 className='title-font font-medium sm:text-4xl text-3xl text-gray-50'>
									4
								</h2>
								<p className='leading-relaxed'>In Progress</p>
							</div>
							<div
								className='p-4 sm:w-1/4 w-1/2'
								data-scroll
								data-scroll-delay='0.05'
								data-scroll-speed='4'
							>
								<h2 className='title-font font-medium sm:text-4xl text-3xl text-gray-50'>
									4
								</h2>
								<p className='leading-relaxed'>Upcoming</p>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	)
}

export default About
