import React from 'react'

import './process.scss'
import processPic from '../../asset/oil.svg'
const Process = () => {
	return (
		<section className='process-container text-gray-600 body-font bg-primary'>
			<div className='text-gray-600 body-font'>
				<div className='container px-5 py-24 mx-auto flex flex-wrap'>
					<div className='flex flex-wrap w-full'>
						<img
							className='gambo lg:w-3/6 md:w-1/2 object-center rounded-lg md:mt-0 mt-12 object-contain'
							src={processPic}
							alt='step'
						/>
						<div className='lg:w-2/5 md:w-1/2 md:pl-12 md:py-6'>
							<div className='content-title'>
								<h3>Business process</h3>
								<h1 className='py-5 mb-8'>
									Understand and apply the business process
								</h1>
							</div>
							<div className='first-content flex relative pb-12'>
								<div className='h-full w-10 absolute inset-0 flex items-center justify-center'>
									<div className='h-full w-1 bg-gray-200 pointer-events-none'></div>
								</div>
								<div className='flex-shrink-0 w-10 h-10 rounded-full bg-white inline-flex items-center justify-center text-white relative z-10'>
									<svg
										fill='none'
										stroke='black'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										className='w-5 h-5'
										viewBox='0 0 24 24'
									>
										<path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
									</svg>
								</div>
								<div className='flex-grow pl-4'>
									<h2 className='font-medium title-font capitalize text-md underline text-gray-50 mb-1 tracking-wider'>
										Discuss
									</h2>
									<p className='leading-relaxed'>
										Lorem ipsum amet consectetur adipiscing do eiusmod tempor
										incididunt ut labore.
									</p>
								</div>
							</div>
							<div className='second-content flex relative pb-12'>
								<div className='h-full w-10 absolute inset-0 flex items-center justify-center'>
									<div className='h-full w-1 bg-gray-200 pointer-events-none'></div>
								</div>
								<div className='flex-shrink-0 w-10 h-10 rounded-full bg-white inline-flex items-center justify-center text-white relative z-10'>
									<svg
										fill='none'
										stroke='black'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										className='w-5 h-5'
										viewBox='0 0 24 24'
									>
										<path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
									</svg>
								</div>
								<div className='flex-grow pl-4'>
									<h2 className='font-medium title-font text-md underline text-gray-50 mb-1 tracking-wider'>
										We Design the Solution
									</h2>
									<p className='leading-relaxed'>
										We will design a new method and effecient solution for your
										business
									</p>
								</div>
							</div>
							<div className='third-content flex relative pb-12'>
								<div className='h-full w-10 absolute inset-0 flex items-center justify-center'>
									<div className='h-full w-1 bg-gray-200 pointer-events-none'></div>
								</div>
								<div className='flex-shrink-0 w-10 h-10 rounded-full bg-white inline-flex items-center justify-center text-white relative z-10'>
									<svg
										fill='none'
										stroke='black'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										className='w-5 h-5'
										viewBox='0 0 24 24'
									>
										<circle cx='12' cy='5' r='3'></circle>
										<path d='M12 22V8M5 12H2a10 10 0 0020 0h-3'></path>
									</svg>
								</div>
								<div className='flex-grow pl-4'>
									<h2 className='font-medium title-font text-md underline text-gray-50 mb-1 tracking-wider'>
										Deal with the best price
									</h2>
									<p className='leading-relaxed'>
										we give our custom design to the client with valuable
										pricing
									</p>
								</div>
							</div>

							<div className='last-content flex relative'>
								<div className='flex-shrink-0 w-10 h-10 rounded-full bg-white inline-flex items-center justify-center text-white relative z-10'>
									<svg
										fill='none'
										stroke='black'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										className='w-5 h-5'
										viewBox='0 0 24 24'
									>
										<path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
										<path d='M22 4L12 14.01l-3-3'></path>
									</svg>
								</div>
								<div className='flex-grow pl-4'>
									<h2 className='font-medium title-font text-md underline text-white mb-1 tracking-wider'>
										Project Closing
									</h2>
									<p className='leading-relaxed'>
										Well documented on what we doing
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div className='infinite-animation flex relative w-screen py-8 border-2 border-red-50'>
				<div className='flex justify-center items-center infinite-animation-content'>
					{client.map((item, index) => {
						return (
							<>
								<img
									className='w-20 h-20 mx-8 border-blue-500 border-2'
									key={index}
									src={item.vector}
									alt={index}
								/>
							</>
						)
					})}
				</div>
			</div> */}
		</section>
	)
}

export default Process
