import React, { useState, useRef, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
//hooks
import useLocomotive from './hooks/useLocomotive'
//component
import { navigationMenu } from './initialData'
import Navbar from './components/navbar/navbar.jsx'
import Homepage from './pages/index.jsx'
import Solution from './pages/solution/solution'
import NavigationBar from './components/navbar/navigationBar'

const App = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [preloader, setPreloader] = useState(true)
	const [timer, setTimer] = useState(1)
	const [scrollPosition, setScrollPosition] = useState(0)
	const scrollEl = useRef(null)
	const id = useRef(null)
	useLocomotive(!preloader, scrollEl, setScrollPosition)
	const clear = () => {
		window.clearInterval(id.current)
		setPreloader(false)
	}

	useEffect(() => {
		id.current = window.setInterval(() => {
			setTimer((timer) => timer - 1)
		}, 1000)
	}, [])

	useEffect(() => {
		if (timer === 0) {
			clear()
		}
	}, [timer])

	return (
		<>
			{preloader ? (
				<div className='fixed flex flex-col justify-center items-center h-screen w-screen bg-black md:flex-row'>
					<div className='w-14 h-14 mx-2.5'>
						<img src='./favicon.png' alt='logo' />{' '}
					</div>
					<span className='hidden md:block'> | </span>
					<h1 className='font-titillium'>Mainfab Engineering</h1>
				</div>
			) : (
				<div
					className='main-container flex flex-col'
					ref={scrollEl}
					data-scroll-container
				>
					<NavigationBar
						data={navigationMenu}
						toggle={isOpen}
						setToggle={setIsOpen}
					/>
					<Switch>
						<Route exact path='/' component={Homepage} />
						<Route exact path='/solution' component={Solution} />
					</Switch>
				</div>
			)}
		</>
	)
}

export default App
