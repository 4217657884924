import imgThree from '../../asset/solution/1.jpg'
import imgTwo from '../../asset/solution/2.jpg'
import imgOne from '../../asset/solution/3.jpg'

export const solution = [
	{
		service: 'Offshore Facalities',
		content:
			'Supply of offshore equipment, Petroleum drilling equipment, Petrochemical and Refinery equipment, Oilfield surface facilities, Drilling & Work over and Production tools, Oilfield chemicals, line pipes, Oil Country Tubular Goods, rental of vessels, related equipment and tools, minor fabrication work, installation and commissioning, maintenance of offshore and onshore facilities. PetroHorizon receives strong support from established equipment manufacturers, boosting the ability to deliver on schedule.',
		img: imgOne,
	},
	{
		service: `Mechanical Maintainance`,
		content:
			' We responsible for the inspection, maintenance, troubleshooting, repair and overhaul of mechanical systems used in the oil and gas industry, They may also be involved in the design and development of machines, machine components and tools.Occupation ativities may includes Setting up and testing equipment to ensure that it functions properly,Analyzing test results in relation to design or rated specifications and test objectives, and modifying or adjusting equipment to meet specifications other machines to modify parts or to fabricate experimental parts for testing, Repairing or adjusting equipment, machines or defective components, replacing worn parts, and returning items to service',
		img: imgTwo,
	},
	{
		service: 'Valve Service Manpower ',
		content:
			'Mainfab Engineering, we are capable to do the Valve Services, Repair and Maintenance accordingly to ensure your operation to be run smoothly at all types for oil & gas industries especially for offshore, onshore and refineries. Tele Cekap Engineering will surely have the manpower and personnel with high competent and qualified to implement the project with minimize supervision. We shall be ensure Safety Procedures and Quality Procedures to be adhered at all times to monitoring the projects on Valve Services including repair and maintenance.',
		img: imgThree,
	},
]
