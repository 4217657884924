import React, { useState, useRef, useEffect } from 'react'
import './carousel.scss'
import {
	RiArrowRightSLine,
	RiArrowLeftSLine,
	RiArrowDownSLine,
	RiArrowUpSLine,
} from 'react-icons/ri'
import { gsap } from 'gsap'

const Carousel = ({ solution }) => {
	const imgEl = useRef()
	const [currentPage, setCurrentPage] = useState(0)
	useEffect(() => {
		gsap.from(imgEl.current.children[0], {
			opacity: 0,
		})
	}, [])

	const nextBtn = () => {
		if (currentPage === 0) {
			setCurrentPage(1)
		} else if (currentPage === 1) {
			setCurrentPage(2)
		} else if (currentPage === 2) {
			setCurrentPage(0)
		}
	}
	const prevBtn = () => {
		if (currentPage === 1) {
			setCurrentPage(0)
		} else if (currentPage === 2) {
			setCurrentPage(1)
		}
	}
	return (
		<div className='carousel-container'>
			<span className='arrow up hidden sm:block'>
				<RiArrowUpSLine onClick={prevBtn} size={40} />
			</span>
			<span className='arrow down hidden sm:block'>
				<RiArrowDownSLine onClick={nextBtn} size={40} />
			</span>
			<div className='carousel-service-title hidden sm:block'>
				<p className='text-black font-titillium'>
					{solution[currentPage].service}
				</p>
			</div>
			<div className='carousel-content-container'>
				<div ref={imgEl} className='carousel-image-container flex'>
					<img
						className={`${currentPage === 0 ? 'active' : ''}`}
						src={solution[0].img}
						alt='slider 1'
					/>
					<img
						className={`${currentPage === 1 ? 'active' : ''}`}
						src={solution[1].img}
						alt='slider 1'
					/>
					<img
						className={`${currentPage === 2 ? 'active' : ''}`}
						src={solution[2].img}
						alt='slider 1'
					/>
					<span className='service-title hidden sm:flex'>
						<p className='text-black'>{solution[currentPage].service}</p>
					</span>
					<span className='arrow left text-white sm:hidden' onClick={prevBtn}>
						<RiArrowLeftSLine size={32} />
					</span>
					<span className='arrow right text-white sm:hidden' onClick={nextBtn}>
						<RiArrowRightSLine size={32} />
					</span>
				</div>
				<div className='carousel-caption-container flex flex-col'>
					<h3 className='text-black mb-5 sm:hidden'>
						{solution[currentPage].service}
					</h3>
					<p className='text-black'>{solution[currentPage].content}</p>
				</div>
			</div>
		</div>
	)
}

export default Carousel
