import React from 'react'
const FeatureModel = ({ data }) => {
	const { feature, content, icon } = data
	return (
		<div className='p-4 md:w-1/3 flex'>
			<div className='w-12 h-12 inline-flex items-center justify-center rounded-full bg-red-100 text-red-500 mb-4 flex-shrink-0'>
				{icon}
			</div>
			<div className='flex-grow pl-6'>
				<h2 className='text-gray-50 text-lg title-font font-medium mb-2'>
					{feature}
				</h2>
				<p className='leading-relaxed text-base'>{content}</p>
				<span className='mt-3 text-gray-50 inline-flex items-center'>
					Learn More
					<svg
						fill='none'
						stroke='currentColor'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						className='w-4 h-4 ml-2'
						viewBox='0 0 24 24'
					>
						<path d='M5 12h14M12 5l7 7-7 7'></path>
					</svg>
				</span>
			</div>
		</div>
	)
}

export default FeatureModel
