import React from 'react'

import { RiMenu4Fill } from 'react-icons/ri'
import { Link } from 'react-router-dom'

import logo from '../../asset/mainfab_logo.svg'
import Dropdown from './dropdown.jsx'

function Navbar({ data, toggle, setToggle }) {
	return (
		<nav
			className={`flex fixed w-screen justify-center items-center py-4 md:py-6 sm:px-9 z-10 font-titillium`}
			role='navigation'
		>
			<div className='flex flex-wrap w-screen container justify-between items-center px-7'>
				<Link
					to='/'
					style={{ backgroundImage: `url(${logo})` }}
					className='flex w-16 h-9 justify-center items-center bg-contain bg-no-repeat bg-center'
				/>
				<span
					onClick={() => setToggle(!toggle)}
					className='md:hidden transform hover:scale-110 transition-transform duration-300'
				>
					<RiMenu4Fill size={32} color='white' />
				</span>
				<div className='hidden md:flex text-white w-3/4 lg:w-8/12 xl:w-1/2 justify-between'>
					{data.map((item) => {
						return (
							<Link
								key={item}
								to={`/${item}`}
								className='text-xl uppercase font-semibold font-titillium'
							>
								{item}
							</Link>
						)
					})}
				</div>
			</div>
			<Dropdown data={data} toggle={toggle} setToggle={setToggle} />
		</nav>
	)
}

export default Navbar
